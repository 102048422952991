import React from "react";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { Button } from "@mui/base";

const LogOut = () => {
  const handleLogOut = async () => {
    try {
      console.log("logging out");
      await signOut(auth);
    } catch (err) {
      console.error(err);
    }
    console.log("logged out");
  };

  return <Button onClick={handleLogOut}>Log Out</Button>;
};

export default LogOut;
