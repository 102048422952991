import React from "react";

const About = () => (
  <div className="about-page">
    <h1 className="about-title">About GoblinLife.org</h1>
    <p className="about-description">
      Welcome to the digital dungeon where curiosity meets chaos! We're a band
      of unconventional minds, digital misfits, and knowledge-hungry goblins,
      all united by our thirst for the extraordinary. Here at GoblinLife, we
      challenge the status quo, embrace the absurd, and hustle harder than a
      gremlin in a silicon mine.
    </p>
    <div className="about-features">
      {/* You can add more content here about your features */}
    </div>
  </div>
);

export default About;
