import React from "react";

const Footer = () => {
  return (
    <footer className="page-footer font-small blue pt-4">
      <div className="container">
        <div className="row row-cols-no-gutters">
          <div className="col-md-4">
            <h5 className="text">GoblinNet</h5>
            <ul className="list-unstyled">
              <li>
                <a href="#!">COMING SOON</a>
              </li>
              <li>
                <a href="#!">GoblinGPT</a>
              </li>
              <li>
                <a href="#!">Goblin Compendium</a>
              </li>
              <li>
                <a href="#!">Goblin University</a>
              </li>
            </ul>
          </div>

          <div className="col-md-4">
            <h5 className="text">Useful links</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/faq">FAQ</a>
              </li>
              <li>
                <a href="/terms">Terms of Service</a>
              </li>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/contact">Contact Us</a>
              </li>
            </ul>
          </div>

          <div className="col-md-4">
            <h5 className="text">Inferior Social Media Platforms</h5>
            <ul className="list-unstyled">
              <li>
                <a href="#!">Facebook</a>
              </li>
              <li>
                <a href="#!">Twitter</a>
              </li>
              <li>
                <a href="#!">Instagram</a>
              </li>
              <li>
                <a href="#!">LinkedIn</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright text-center">
        © 2024 Glass Goblin OCDware{" "}
        <a href="https://goblinlife.org/"> GoblinLife.org</a>. All rights
        reserved.
      </div>
    </footer>
  );
};

export default Footer;
