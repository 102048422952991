import React from "react";

const HomePage = () => (
  <div className="homepage">
    <h1 className="homepage-title">Welcome to GoblinLife.org!</h1>
    <p className="homepage-description">
      A digital sanctuary for unconventional minds, where ideas clash, memes
      spark laughter, and spirited debates challenge the status quo.
    </p>
    <div className="homepage-features">
      <div className="feature">
        <h2 href="https://forum.goblinlife.org">Goblin Forum</h2>
        <p>
          Engage in lively discussions with fellow Goblins, sharing unique
          perspectives and experiences.
        </p>
      </div>
      <div className="feature">
        <h2>GG's Blog</h2>
        <p>
          Insights, advice, and unconventional perspectives on life, technology,
          and everything in-between from the Goblin King, GG.
        </p>
      </div>
      <div className="feature">
        <h2>Goblin Compendium</h2>
        <p>
          A treasure trove of curated articles, tools, and resources designed to
          empower you on your unconventional journey.
        </p>
      </div>
      <div className="feature">
        <h2>Goblin University</h2>
        <p>
          Level up your skills, expand your knowledge, and embrace the art of
          the hustle in our digital academy.
        </p>
      </div>
    </div>
  </div>
);

export default HomePage;
