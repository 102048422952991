import React, { useState } from "react";
import { auth, googleProvider } from "../firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";

export const LoginForm = () => {
  console.log("LoginForm component rendered");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signInWithGoogle = async () => {
    try {
      console.log("Signing in with Google");

      await signInWithPopup(auth, googleProvider);
      console.log("Google sign-in successful");
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async (event) => {
    console.log(email);
    event.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Email sign-in successful");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type="submit">Sign in</button>
      <button type="button" onClick={signInWithGoogle}>
        Sign in with Google
      </button>
    </form>
  );
};
