import React from "react";
import "../styles/LandingPage.css";
import Hero from "../components/Hero";

const LandingPage = () => {
  return (
    <div className="landing-page">
      <Hero />
    </div>
  );
};

export default LandingPage;
