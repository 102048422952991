import React from "react";

const Terms = () => (
  <div className="terms-page">
    <h1 className="terms-title">Terms of Service</h1>
    <p className="terms-description">
      By using GoblinLife.org, you agree to our totally legit and
      not-at-all-made-up terms of service. Basically, be cool, don't spam, and
      don't blame us if you accidentally summon a demon while browsing our site.{" "}
    </p>
    {/* You can expand on the terms of service here */}
  </div>
);

export default Terms;
