import React from "react";

const GoblinManifesto = () => (
  <div className="manifesto-page">
    <h1 className="manifesto-title">The Goblin Manifesto</h1>
    <ol className="manifesto-list">
      <li>
        <strong>We question everything:</strong> No sacred cows here. We poke,
        prod, and dissect every idea with goblin-like curiosity.
      </li>
      <li>
        <strong>We embrace the unconventional:</strong> Normal is boring. We
        thrive on the fringes of knowledge, where the weird and wonderful
        reside.
      </li>
      <li>
        <strong>We hustle relentlessly:</strong> We're not afraid of hard work.
        We're driven by a relentless pursuit of our goals, fueled by caffeine
        and questionable life choices.
      </li>
      <li>
        <strong>We celebrate individuality:</strong> Goblins come in all shapes
        and sizes. We believe in embracing our unique quirks and letting our
        goblin flags fly.
      </li>
      <li>
        <strong>We conquer with laughter:</strong> Life's too short to be
        serious all the time. We believe in wielding humor as a weapon against
        the mundane.
      </li>
    </ol>
    <p className="manifesto-cta">
      Join the goblin revolution. Unleash your inner goblin!
    </p>
  </div>
);

export default GoblinManifesto;
