import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.scss";

import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import LandingPage from "./pages/LandingPage";
import HomePage from "./pages/HomePage";
import Blog from "./pages/Blog";

import About from "./pages/About";
import Contact from "./pages/Contact";
import GoblinManifesto from "./pages/GoblinManifesto";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Faq from "./pages/Faq";

import Page from "./pages/Page";
// import readMarkdown from "./util/processMarkdown";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <Router>
      <Header />
      <React.Suspense
        fallback={<div>Loading... PLEASE DON'T LEAVE - I LOVE YOU</div>}
      >
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route
            path="/logout"
            element={
              <button onClick={() => setIsLoggedIn(false)}>Log Out</button>
            }
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/goblin-manifesto" element={<GoblinManifesto />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </React.Suspense>
      <Footer />
    </Router>
  );
}

export default App;
