import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import "../styles/Hero.scss";

const Hero = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Add any on-load animations or effects here if needed
  }, []);

  // TODO: check login status, route to /home if logged in
  const handleClicc = () => {
    console.log("clicked");
    navigate("/home");
  };

  return (
    <div className="hero">
      <div className="hero-content">
        <h1>Welcome to GoblinLife.org</h1>
        <p>Your guide to all things goblin.</p>
        <Button variant="contained" color="primary" onClick={handleClicc}>
          Explore Now
        </Button>
      </div>
    </div>
  );
};

export default Hero;
