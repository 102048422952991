import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA0r81HTvARuCQQN8lmunpj6vW559ZcodQ",
  authDomain: "goblinlife-website.firebaseapp.com",
  projectId: "goblinlife-website",
  storageBucket: "goblinlife-website.appspot.com",
  messagingSenderId: "223998699918",
  appId: "1:223998699918:web:170320521d3cc2e6d39cdf",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
