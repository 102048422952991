import "../styles/Blog.scss";

import React from "react";
import { useState, useEffect } from "react";
import { db } from "../firebase";
import { format } from "date-fns";

import { getDocs, collection } from "firebase/firestore";

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  const postsCollection = collection(db, "blog");

  useEffect(() => {
    const getPosts = async () => {
      try {
        const data = await getDocs(postsCollection);
        const filteredData = data.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setBlogPosts(filteredData);
      } catch (error) {
        console.log(error);
      }
    };

    getPosts();
  }, []);

  return (
    <div className="blog-container">
      <h1>GG's Ramblings</h1>
      <div className="posts-grid">
        {blogPosts.map((post) => (
          <div className="post-card" key={post.id}>
            <div className="post-header">
              <h3>{post.data.title}</h3>
              <p className="post-author">by {post.data.author}</p>
            </div>
            <p className="post-date">
              {format(post.data.createdAt.toDate(), "MMMM d, yyyy")}
            </p>
            <p className="post-content">{post.data.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
