import React, { useState, setOpen } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import { LoginForm } from "./LoginForm";
import { SignupForm } from "./SignUpForm";
import LogOut from "./LogOut";

// TODO: disable login and signup for logged in users
// TODO: disable logout for logged out users
// TODO: track login status and export to App.js
// TODO: move auth logic to separate components

const Header = () => {
  const navigate = useNavigate();

  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);

  const handleOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleOpenSignup = () => {
    setOpenSignup(true);
  };
  const handleCloseSignup = () => {
    setOpenSignup(false);
  };
  const handleBlogClick = () => {
    navigate("/blog");
  };

  return (
    <AppBar position="static" className="App-header">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <a href="/">GoblinLife.org</a>
        </Typography>
        <Button color="inherit" href="https://forum.goblinlife.org">
          GoblinLife Forum
        </Button>
        <Button color="inherit" onClick={handleBlogClick}>
          Blog
        </Button>
        <Button color="inherit" onClick={handleOpenLogin}>
          Login
        </Button>
        <Dialog open={openLogin} onClose={handleCloseLogin}>
          <DialogTitle>Login</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <LoginForm />
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Button color="inherit" onClick={handleOpenSignup}>
          Signup
        </Button>
        <Dialog open={openSignup} onClose={handleCloseSignup}>
          <DialogContent>
            <DialogContentText>
              <SignupForm />
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <LogOut />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
