import React from "react";

const Faq = () => (
  <div className="faq-page">
    <h1 className="faq-title">Goblin FAQ - Where Curiosity Meets Chaos</h1>
    <dl className="faq-list">
      <dt>What exactly *is* a Goblin?</dt>
      <dd>
        We're not talkin' about those grotesque creatures from your grandma's
        fairy tales. Here at GoblinLife, a Goblin is a state of mind. We're the
        rebels, the misfits, the digital nomads of knowledge. We question
        everything, embrace the unconventional, and hustle harder than a gremlin
        in a pawn shop. We're driven by curiosity, fueled by chaos, and bound by
        our shared love for the absurd.
      </dd>

      {/* Add more Q&A pairs here */}

      <dt>Wait, is this whole "Goblin thing" for real?</dt>
      <dd>
        The Goblin spirit is as real as the code that powers this website, my
        friend. It's a mindset, a rejection of the ordinary, and a celebration
        of the unconventional. We're here to build something different,
        something that challenges the norm and empowers those who dare to be
        different. So buckle up, goblin, and get ready for the ride!
      </dd>
    </dl>
  </div>
);

export default Faq;
