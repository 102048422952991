import React from "react";

const Privacy = () => (
  <div className="privacy-page">
    <h1 className="privacy-title">Privacy Policy</h1>
    <p className="privacy-description">
      At GoblinLife.org, we respect your privacy (mostly). We don't collect any
      personal information... unless you give it to us willingly. We promise not
      to sell your data to any shady goblin corporations (we haven't found any
      buyers yet).
    </p>
    {/* You can add more detailed privacy information here */}
  </div>
);

export default Privacy;
