import React, { useState } from "react";
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "../firebase";

export const SignupForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      console.log("Signing up with email:", email);
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("User created:", user.uid);
      await db.collection("users").doc(user.uid).set({
        displayName,
      });
      console.log("User data stored in Firestore");
      setEmail("");
      setPassword("");
      setDisplayName("");
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      console.log("Signing in with Google");
      const { user } = await signInWithPopup(auth, provider);
      console.log("Google sign-in successful:", user.uid);
      // Store user data in Firestore
      await db.collection("users").doc(user.uid).set({
        displayName: user.displayName,
      });
      console.log("User data stored in Firestore");
    } catch (error) {
      console.error("Error during Google sign-in:", error);
    }
  };

  return (
    <form onSubmit={handleSignUp}>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        type="text"
        placeholder="Display Name"
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
      />
      <button type="submit">Sign Up</button>
      <button type="button" onClick={handleGoogleSignIn}>
        Join with Google
      </button>
    </form>
  );
};
