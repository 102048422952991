import React from "react";

const Contact = () => (
  <div className="contact-page">
    <h1 className="contact-title">Summon a Goblin!</h1>
    <p className="contact-description">
      Want to get in touch? We might be lurking in the shadows, but we're always
      open to hearing from fellow goblins.
    </p>
    <ul className="contact-list">
      <li>
        <strong>Email:</strong> gg@goblinlife.org (We check it... sometimes.)
      </li>
      <li>
        <strong>Discord:</strong> Coming soon! (Prepare for maximum chaos.)
      </li>
      <li>
        <strong>Twitter:</strong> @GoblinLifeOrg (Follow us for memes and
        ramblings.)
      </li>
    </ul>
  </div>
);

export default Contact;
